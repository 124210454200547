const ProgressLine = ({value}) => {
return (
      <div
        className="wrapper"
        role="progressbar"
        aria-valuemin='0'
        aria-valuemax='30'
        aria-valuenow={value}
      >
        <div className="barContainer">
          <div className="filler" style={{ width: `${value * 3.33}%` }}>
            <div
              className="fillerBackground"
              style={{ width: '100vw' }}
            />
          </div>
        </div>
      </div>
    );
  };
  
export default ProgressLine;