import React, { useState } from 'react';
import mg from './images/mg.jpg';
import ob from './images/ob.jpg';
import am from './images/am.jpg';
import vc from './images/vc.jpg';
import logoGreen from './images/LogoGreen.svg';
import "./landing.css";
import email from './images/email.png';


function Landing(props) {
    const introduce = 'Компания IDDQD создана командой единомышленников, ставящих своей целью вывести отечественный геймдев на принципиально новый уровень. Наш богатый опыт и профессиональные знания в сферах образования, информационных технологий, государственного устройства и банковского сектора позволяют внедрять игры туда, где их еще не было, не ограничиваясь миром развлечений.'
    const gamedev = 'Разработка браузерных, мобильных и десктоп игр. Публикация и продвижение игр на таких площадках как: Игры ВКонтакте, Yandex Игры, App Store, Google Play, Rustore, Аврора. В качестве основного движка для разработки игр используем Cocos Creator, свободно распространяемый и не имеющий ограничений по использованию на территории Российской Федерации.'
    const gamedev_price = 'Стоимость разработки игр под заказ рассчитывается индивидуально.'
    const devpo = 'Разработка программного обеспечения как по готовому техническому заданию, так и по разработанному на основе технических требований заказчика. Используем различные технологические стеки и фреймворки. '
    const devpo_price = 'Стоимость разработки программного обеспечения под заказ от 5 000 рублей в час.'
    const gameTrainers = 'Разработка учебных пособий и тренажеров с использованием игровых механик. Выполняем проекты как с глубокой проработкой сюжета, так и с ненавязчивым нарративом. '
    const gameTrainers_price = 'Стоимость разработки тренажера под заказ от 7 000 рублей в час.'
    const gamification = 'Внедряем игровые механики в уже описанные бизнес-процессы, а также описываем бизнес процессы с учетом внедрения игровых механик. Богатый проектный опыт членов нашей команды позволяет применять принципы геймификации практически в любой сфере деятельности. '
    const gamification_price = 'Стоимость разработки геймификации от 5 000 рублей в час.'

    const [information, setInformation] = useState(introduce);
    const [price, setPrice] = useState('');

    function handlePlayClick() {
        document.querySelector('body').classList.remove('blackBack');
        document.querySelector('body').classList.add('game-bg');
        document.querySelector('.main').classList.remove('displayNone');
        document.querySelector('.stopgame-button').classList.remove('displayNone');
        props.setLandingVisibility(false);
        if (props.situationVisibility) {
            if (props.cardsVisibility) {
                props.setTimerActive(true);
                props.setTimerVisibility(true);
            }
        }
    }

    return (
        <div className='landing'>
            <header className='header'>
                <div className='header__border-text'></div>
                <div className='header__border-text'></div>
                <div className='header__border-text header__vertical-text'></div>
                <div className='header__border-text header__vertical-text'></div>
                <div className='header__content'>
                    <img className='header__logo' src={logoGreen} alt={'iddqd logo'} />
                    <button className='button header__play-button' onClick={handlePlayClick}></button>
                </div>
            </header>

            <h2 className='people__heading'>Направления деятельности</h2>
            <section className='feedback features'>
                <div className='feedback__info features__info'>
                    <p className='feedback__p features__p hover_p' onClick={() => { setInformation(gamedev); setPrice(gamedev_price) }}>Разработка игр</p>
                    <p className='feedback__p features__p hover_p' onClick={() => { setInformation(devpo); setPrice(devpo_price) }}>Разработка программного обеспечения</p>
                    <p className='feedback__p features__p hover_p' onClick={() => { setInformation(gameTrainers); setPrice(gameTrainers_price) }}>Разработка игровых тренажеров</p>
                    <p className='feedback__p features__p hover_p' onClick={() => { setInformation(gamification); setPrice(gamification_price) }}>Геймификация бизнес-процессов</p>
                </div>
                <p className='feedback__p features__introduce'>{information} <br /> <p>{price}</p></p>
            </section>
            <h2 className='people__heading'>Наша команда</h2>
            <section className='people'>
                <article className='person'>
                    <img src={mg} className='person__img' alt='portrait' />
                    <p className='person__name'>Марат Герасимов</p>
                    <p className='person__job'>CEO</p>
                </article>
                <article className='person'>
                    <img src={ob} className='person__img' alt='portrait' />
                    <p className='person__name'>Олег Бедрик</p>
                    <p className='person__job'>CFO</p>
                </article>
                <article className='person'>
                    <img src={am} className='person__img' alt='portrait' />
                    <p className='person__name'>Андрей Муринович</p>
                    <p className='person__job'>CTO</p>
                </article>
                <article className='person'>
                    <img src={vc} className='person__img' alt='portrait' />
                    <p className='person__name'>Валентин Черкас</p>
                    <p className='person__job'>BDM</p>
                </article>
            </section>

            <section className='feedback'>
                <div className='feedback__info'>
                    <h2 className='feedback__heading'>Обратная
                        связь</h2>
                    <p className='feedback__p'>hello@iddqd.pro</p>
                    <p className='feedback__p'>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ИДДКД»/</p>
                    <p className='feedback__p'>Юридический адрес: 199106, город Санкт-Петербург, Кожевенная линия, д. 40 литера А, помещ. 11 (37-н)</p>
                    <p className='feedback__p noMargin'>ИНН  9719019582</p>
                    <p className='feedback__p noMargin'>ОГРН 1217700485012</p>
                    <p className='feedback__p noMargin'>КПП  771901001</p> <br/>
                    <p className='feedback__p noMargin'>
                        <a href={require('./vypiska_iddqd.pdf')} target='_blank' rel="noreferrer" style={{fontWeight: 'bold', textDecoration: 'underlined', color: 'rgb(148, 163, 184)'}}>Номер в реестре аккредитованных организаций, осуществляющих свою деятельность в области информационных технологий:</a> <br/>
                        49398 (решение № АО-20230810-13743749027-3 от 15.08.2023)
                    </p>
                </div>
                <iframe className='feedback__iframe' title='map_iframe'
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3A0c8616ea829874a4ce6bd845eaa4077a807b6976a31195a9d182642d05253982&amp;source=constructor"
                    width="655" height="500" frameBorder="0"></iframe>
            </section>
            <footer className='footer'>
                <p>© 2024 IDDQD, Inc. All rights reserved.</p>
                <p>Кожевенная линия, 40, <br />
                    Санкт-Петербург, 199106</p>
                <a href='mailto:hello@iddqd.pro'><img className='feedback__email' src={email} alt='send email' /></a>
            </footer>
        </div>
    );
}

export default Landing;
