import React from "react";
import ProgressLine from "./ProgressBar";

function CountDown(props) {
    const noAnswer = props.answerList.find(item => item.question === props.noAnswerCount + 1);
    React.useEffect(() => {
        if (props.seconds > 0 && props.timerActive) {
           var timerID = setTimeout(props.setSeconds, 1000, props.seconds - 1);
        } else {
            props.setTimerActive(false);
        }

        if (props.seconds === 0 && !props.timerActive) {
            props.resetTimer();
            props.endings[props.noAnswerCount] = noAnswer.ending;
            props.setSituationVisibility(!props.situationVisibility);
            props.setTimerVisibility(!props.timerVisibility);
            document.querySelector('.answers__button').classList.remove('button-toLeft');
            if (props.answerList[props.noAnswerCount].redFlag) {
                props.setRedFlag(props.redFlagSum + 1)
            }
        }

        if (document.hidden) {
            props.setTimerActive(false);
            props.setCardsVisibility(false);
            props.setSituationVisibility(true);
            props.setTimerVisibility(false);
            document.querySelector('.answers__button').classList.remove('button-toLeft')
        }

        return () => clearTimeout(timerID);
    })


    return (
            <div className='timerStyle' ><p>{props.seconds}</p>
            <ProgressLine value={props.seconds}/>
            
            </div>
    );
}

export default CountDown

/*   */