import React, {useEffect, useState} from 'react';

function Outcome(props) {
    const[result, setResult] = useState('За всю свою жизнь, вы старались не вредить окружающим, а иногда у вас выходило делать что-то хорошее для земного мира. Небесные силы по достоинству оценили ваши поступки и решили дать вам возможность  творить добрые дела, теперь вы Архибугалтер. Вы следите за тем, чтобы земные работяги получали все свои кровно заработанные деньги вовремя и без обмана!')
    const [count, setCount] = useState(0);
    const [deathVisibility, setDeathVisibility] = useState(true);
    const [endingVisibility, setEndingVisibility] = useState(false);
    const [resultVisibility, setResultVisibility] = useState(false);

    useEffect(() => {
        if (props.redFlagSum > 4 && count >= 8) {
            document.querySelector('.outcome').classList.remove('outcome__img7');
            document.querySelector('.outcome').classList.add('outcome__bad');
            setResult('Ваша жизнь, была грешна, иногда даже очень сильно. Вас забрали к себе под крыло силы ада. Они поразмыслили и решили, что такому таланту как вы, не стоит пропадать и выделили очень интересную должность при каком-то демоническом короле. Теперь вы начальник по веселью!  В ваши обязанности входит каждый день составлять программу, которая рассмешит короля. И лучше вам постараться, иначе…');
        }

        if (props.redFlagSum <= 4 && count >= 8) {
            document.querySelector('.outcome').classList.remove('outcome__img7');
            document.querySelector('.outcome').classList.add('outcome__good');
        }

        if (!resultVisibility && count > 7) {
            document.querySelector('.outcome').classList.remove('outcome__img7','outcome__img6','outcome__img5','outcome__img4','outcome__img3','outcome__img2','outcome__img1',);
            document.querySelector('.button').classList.add('hidden');
            document.querySelector('#restart-btn').classList.remove('displayNone');
            setEndingVisibility(false);
            setResultVisibility(true);
        }

        if (count > 0) {
            document.querySelector('.outcome').classList.add(`outcome__img${count}`, 'outcome__img');
        }

        if (resultVisibility) {
            document.querySelector('.outcome__tip').classList.add('displayNone');
        }
        
    }, [count, props.redFlagSum, resultVisibility])

    function handleDeathClick() {
        document.querySelector('.death').classList.add('hidden');
        document.querySelector('.outcome').classList.remove('outcome-full');
        setCount(count+1);
        setDeathVisibility(false);
        setEndingVisibility(true);
        props.setUIVisibility(true);
    }

    function handleCount() {
        setCount(count + 1)
    }

    function handleRestart() {
        document.location.reload();
    }

    return (
        <div className='outcome outcome-full' >
            {deathVisibility &&
                <div className='death'>
                    <p className='outcome__text01'>К вам домой врывается какой-то горец с мечом в руках и сносит им вашу голову. Чит-код вас не спасает, он перестал действовать…</p>
                    <button className='button next-btn' id='button' onClick={handleDeathClick}></button>
                </div>
            }
            {props.UIVisibility && <div className='answers-section'>
                <div className='outcome__tip' ></div>
                <button className={'button answers__button'} id='button' onClick={handleCount}>
                </button>
                <button className='button answers__button displayNone' id='restart-btn' onClick={handleRestart}>
                </button>
                {endingVisibility && <p className='situation__text'>{props.endings[count-1]}</p>}
                {resultVisibility && <p className='situation__text'>{result}</p>}
            </div>}
        </div>
    );
}

export default Outcome;
