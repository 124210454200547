import React, {useEffect, useState} from "react";
import './App.css';
import InitialFrame from './components/InitialFrame';
import Situation from "./components/Situation";
import Outcome from "./components/Outcome";
import Landing from "./components/Landing/Landing";
import Policy from "./components/Policy";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
    const situationList = [
        'Ваше племя перебили, разорванные тела ваших детей находятся у вас под ногами. Вы стоите один, окруженный кровожадно ухмыляющимися захватчиками. Сжимая зубы и  крепко держа дубину, вы решаете что делать...',
        'Вы в крупном поселении, которое в будущем назовут городом. Вокруг кипит стройка, возводят большую каменную башню. По задумке она достанет до самого неба. Однако вы приехали сюда ради прелестных жриц любви Иштар. Теребя свои монеты,  вы начинаете  сомневаться в своем выборе. Гигантская массивная  стройка устремленная вверх никак не выходит из головы...',
        'Вы скитаетесь по миру сотни лет, жизнь превратилась в бессмысленную череду дней и ночей. Наконец, вы решаете избавить себя от данной бессмыслицы…',
        'Вы в Риме, городе, куда ведут все дороги. Вы разбогатели и можете позволить себе практически все. Сегодня вечером вы решили поразвлечься… ',
        'На дворе эпоха Возрождения, вы во Флоренции. До вас дошла небывалая слава Микеланджело и Леонардо да Винчи. Вы конечно же ознакомились с их работами. Однако все было не так, вы видели это своими глазами! И теперь этим лгунам и выдумщикам такая слава? Это никуда не годится, вы решаете доказать миру, что они пустышки, а настоящий гений вы!',
        'Сейчас все делают большие деньги на Новом Свете. Вы решаетесь устроить свой промысел на открытых землях и получить с этого солидный навар.',
        'Вы попали в тренд мировой истории, теперь вы владелец мировой IT-компании, у вас свои криптобиржи и социальные сети. Кажется, еще немного и мир падет к вашим ногам. Впервые за долгое время вы задумались о мировом господстве…'];
    const shuffle = (array) => {
        let m = array.length, t, i;
        // Пока есть элементы для перемешивания
        while (m) {
            // Взять оставшийся элемент
            i = Math.floor(Math.random() * m--);
            // И поменять его местами с текущим элементом
            t = array[m];
            array[m] = array[i];
            array[i] = t;
        }
        return array;
    }
    const answerList = shuffle([
        {
            question: 1,
            id: 1,
            text: 'Не сопротивляться нападающим и последовать за семьей в загробный мир. С ними всяко лучше, чем с этими гадами!',
            redFlag: false,
            ending: 'На вас разом накинулись все нападающие. Однако, разбив кулаки до костяшек и сломав свое оружие об вас, они в ужасе разбежались, приняв вас за магическое существо.'
        },
        {
            question: 1,
            id: 2,
            text: 'Взять себя в руки и броситься утекать на чудном колесном изобретении! Все-таки жить хочется!',
            redFlag: false,
            ending: 'Вы садитесь за руль странного средства передвижения и начинаете толкать ногами со всех сил. Однако транспорт не рассчитан на одного и вы не можете сдвинуться с места. Вас окружают гогочущие захватчики, выкидывают из транспорта и пинками прогоняют куда подальше.'
        },
        {
            question: 1,
            id: 3,
            text: 'Броситься в атаку на захватчиков с дубиной. ',
            redFlag: true,
            ending: 'Одолеваемый гневом, вы  расправляетесь с захватчиками самым жестоким способом. А чего они ожидали, столкнувшись с неуязвимым?'
        },
        {
            question: 1,
            id: 4,
            text: 'Сделать вид, что ничего не замечаете, и продолжить заниматься обычными делами... А потом напасть на ничего не подозревающих врагов!',
            redFlag: true,
            ending: 'Вы, как ни в чем не бывало начинаете собирать рыбу с сушки, как будто не замечаете происходящего. Однако захватчики решают ударить со спины. У них бы и получилось, если бы не код “IDDQD” на неуязвимость. Вы просто разворачиваетесь к напавшим и разрываете их всех голыми руками.'
        },
        {
            question: 1,
            id: 5,
            text: 'Заманить  напавших в “неприметное” медвежье логово поблизости.',
            redFlag: false,
            ending: 'Вы кидаетесь со всех ног в медвежью берлогу. Подбегая к ней, вы замечаете, что напавшие так и стоят на месте. Вы хотите развернуться и приманить врагов, но уже поздно. Медведь вышедший из берлоги кидается на вас и начинается схватка между вами и зверем. Захватчики, спокойно разворачиваются и уходят прочь.'
        },
        {
            question: 2,
            id: 1,
            text: 'Вложусь в постройку башни, все-таки что-то в ней есть! Особенно привлекает то, как она пронзает небо… ',
            redFlag: false,
            ending: 'Вы вкладываетесь в постройку башни. Однако, позже она рушится и входит в притчи во языцех как вавилонская башня. '
        },
        {
            question: 2,
            id: 2,
            text: 'Начать торговаться со жрицами, пытаясь снизить цену на услуги.',
            redFlag: true,
            ending: 'Все начинаете торговаться как на базаре. Однако жрицы возмущены вашим поведением и разговор перерастает в ссору. На этом ничего не заканчивается, рабочие привлеченные жрицами, начинают вас колотить и выгоняют из города. '
        },
        {
            question: 2,
            id: 3,
            text: 'Что-то здесь не так: прелестные жрицы, башня пронзающая небо... Лучше убраться отсюда подобру-поздорову и пожертвовать монеты храму!',
            redFlag: false,
            ending: 'Избавившись от монет, вы почувствовали себя лучше. Главное в жизни - это благостное самочувствие!'
        },
        {
            question: 2,
            id: 4,
            text: 'Жриц много, а вы один. Организовать коллективный поход вместе с рабочими стройки.',
            redFlag: true,
            ending: 'Все заканчивается самой долгой оргией в вашей жизни. Под ее конец вы оказываетесь в коровнике! Вот это приключение…'
        },
        {
            question: 2,
            id: 5,
            text: 'Какие жрицы? Лучше сходить в таверну и потом самостоятельно, в уединенном месте, избавиться от похоти!',
            redFlag: true,
            ending: 'Выпив пару бочек алкоголя в таверне, вы падаете в лужу грязи и засыпаете.'
        },
        {
            question: 3,
            id: 1,
            text: 'Может, меня кто-то съест, например, кит?',
            redFlag: true,
            ending: 'Бросившись в пасть морского зверя, вы его ненароком убиваете. Кит вами подавился и задохнулся.'
        },
        {
            question: 3,
            id: 2,
            text: 'Стоит придать смысл своей жизни. Например, можно посмотреть куда доползет эта черепаха.',
            redFlag: false,
            ending: 'Наблюдая за черепахой месяцами, в какой-то момент вы находите душевный покой.'
        },
        {
            question: 3,
            id: 3,
            text: 'Отправиться в отшельничество и заняться своим духовным состоянием.',
            redFlag: false,
            ending: 'Вы отправляетесь в далекие страны и путешествуете по миру от Эллады до Гипербореи.'
        },
        {
            question: 3,
            id: 4,
            text: 'Одному скучно, нужно захватить землю и поработить ближайших жителей!',
            redFlag: true,
            ending: 'Начать захват вы планируете в ближайшей деревушке. Однако все кончается не так, как вы планировали. Жители решают биться с вами до конца за свои родные земли. Вы уходите в смущении и стыде, пораженный их упорством.'
        },
        {
            question: 3,
            id: 5,
            text: 'А может я чего-то не понимаю? Отправлюсь к эллинам на учебу и стану философом…',
            redFlag: false,
            ending: 'Пройдя обучение у лучших мыслителей своего времени, вы остаетесь в Афинах, бродите по городу и ведете философские беседы с её жителями.'
        },
        {
            question: 4,
            id: 1,
            text: 'Я богат? Да! Я влиятелен? Да! Я пьян? Очень! Значит идем бить морду императору!',
            redFlag: true,
            ending: 'По прибытии во дворец, вас хватают преторианцы, после чего судят и продают в рабство. Спустя десятилетия заключения, вам удается сбежать из неволи и обрести свободу.'
        },
        {
            question: 4,
            id: 2,
            text: 'Что может быть веселее огня? Подожгу Рим, а потом свалю это на кого-нибудь…',
            redFlag: true,
            ending: 'Вы с подельниками поджигаете лавки юго-восточней Большого цирка. Огонь распространяется быстро и к утру большая часть Рима в огне. Пожар длился семь ночей и шесть дней. Через неделю вы поняли, что сгорела ваша вилла со всеми вашими накоплениями и пожитками. После грандиозного пожара вы с сожалением воскликнули - что же делать?!'
        },
        {
            question: 4,
            id: 3,
            text: 'Щедрость и благоразумие, вот чем надлежит заниматься в свободное время. Пойду раздавать деньги беднякам и делать мир лучше.',
            redFlag: false,
            ending: 'Вы отправляетесь в трущобы с мешочками денег. Однако только вы успели в них зайти, вас хватают, избивают и отбирают деньги. Вы приходите в себя под утро перемазанный грязью и собственной кровью.'
        },
        {
            question: 4,
            id: 4,
            text: 'Бордели, таверны и гладиаторские бои - мое все.',
            redFlag: true,
            ending: 'Вы начинаете свои развлечения с посещения таверны и на этом все кончается. Для вашей памяти. Непонятно где и сколько вы выпили вина, но вы очнулись с амфорой напитка Бахуса в Александрии в Египте.'
        },
        {
            question: 4,
            id: 5,
            text: 'А может отправиться в паломничество по священным местам? Кажется, отличная идея…',
            redFlag: false,
            ending: 'Вы нанимаете корабль для паломничества по святым местам. Ваш путь пролегает через гору Олимп и Дельфы, вплоть до Гипербореи. Однако на ваш корабль нападают пираты, вас и ваших слуг обращают в рабство и продают в Египет возделывать пшеничные поля для Рима.'
        },
        {
            question: 5,
            id: 1,
            text: 'Сожгу этих лжецов и их гнусные произведения! Правда только одна, и это моя правда!',
            redFlag: true,
            ending: 'Вы отправляетесь по тихим улочкам Флоренции жечь лжеца Микеланджело. Однако Медичи, которые ему покровительствуют, нанесли удар первыми. Он стал бы смертельным, если бы не код на неуязвимость.'
        },
        {
            question: 5,
            id: 2,
            text: 'Суровая жизнь научила меня, что от конкурентов избавляются…',
            redFlag: true,
            ending: 'Вы идете в злачные места, чтобы нанять головорезов для своего коварного плана. Однако вы допускаете роковую ошибку, вы говорите им, где ваша мастерская и кто вы. Вместо рискованной работёнки головорезы решают стукнуть дубинкой вас по голове и обнести вашу мастерскую.'
        },
        {
            question: 5,
            id: 3,
            text: 'Наймусь к ним в ученики и раскрою их секреты искусства. А дальше, мой талант и их мастерство явят этому миру правду!',
            redFlag: false,
            ending: 'Нанявшись в ученики к да Винчи, вы быстро поняли, каким незаурядным талантом обладает ваш учитель. На вас снизошло откровение, что не человек выбирает дар, он приходит свыше.'
        },
        {
            question: 5,
            id: 4,
            text: 'Обращусь к ведьмам и колдунам, нашлю на них порчу и проклятия! Никто не смеет забирать мою славу!',
            redFlag: true,
            ending: 'Прознав о местной ведьме, вы назначаете ей встречу ночью. По прибытии к ней вы обнаруживаете засаду инквизиторов. На суде вас приговорили к пожизненному заключению. Однако вам везет, благодаря рекомендациям друзей из Флоренции, вас отпустили после двадцати лет заключения!'
        },
        {
            question: 5,
            id: 5,
            text: 'А может они действительно так хороши? Закажу у них и себе что-нибудь...',
            redFlag: false,
            ending: 'Вы решаете заказать пару произведений да Винчи и Микеланджело. Может сейчас это и не окупится, но дальше может и окупиться. Как с припасенными со времен Римской империи свитками!'
        },
        {
            question: 6,
            id: 1,
            text: 'Пусть другие зарабатывают на Новом Свете, а я буду зарабатывать на них! Начнем грабить приплывающие суда в Европу!',
            redFlag: true,
            ending: 'Вы покупаете корабль, пушки, нанимаете команду и уплываете в Карибский бассейн. Со временем вы решаете обосноваться на Тортуге и приглашаете других пиратов к себе.'
        },
        {
            question: 6,
            id: 2,
            text: 'Отправиться с торговой миссией в новые земли и устроить взаимовыгодный обмен.',
            redFlag: false,
            ending: 'Вы нанимаете корабль и команду. В Новый свет вы везете печатные станки, станки для монет и другое оборудование. А назад везете сахар и табак. Очень скоро вы делаете солидное состояние на торговле.'
        },
        {
            question: 6,
            id: 3,
            text: 'А может заняться работорговлей в Новом Свете? В древнем Риме это было прибыльное дело…',
            redFlag: true,
            ending: 'С командой вы отправляетесь к берегам Африки. Ваш корабль попадает в шторм и тонет. Выживаете только вы один, благодаря неуязвимости. Вы не можете выбраться из водяной ловушки два года, пока вас чудом не обнаруживают португальские корсары.'
        },
        {
            question: 6,
            id: 4,
            text: 'А может, деньги есть и в Старом Свете? Обратить внимание на доходность хозяйств в Голландии и вложиться в них.',
            redFlag: false,
            ending: 'Вы открываете несколько мануфактур, нацеленных на производство передовых товаров. Со временем ваш доход начинает превышать доход торговцев и корсаров Нового Света.'
        },
        {
            question: 6,
            id: 5,
            text: 'Деньги это хорошо, а еще лучше вложиться в книгопечатание, денег будет немного, но на жизнь хватит. А ещё и дело хорошее!',
            redFlag: false,
            ending: 'Вы открываете небольшую мануфактуру по книгопечатанию в Пруссии. Со временем, она приобретает местное значение. На доход от продажи книг, вы решаете открыть школу для местных жителей, где будут обучать грамоте и письму.'
        },
        {
            question: 7,
            id: 1,
            text: 'Нужно избавиться от конкурентов и отобрать их компании! Тогда мир точно падет к моим ногам!',
            redFlag: true,
            ending: 'Вы начинаете планомерно скупать компании конкурентов. Где не удается приобрести по доброй воле, вы начинаете запугивать, шантажировать, убивать. В какой-то момент вас просто сажают по антимонопольному законодательству, так как вы устраивали все сделки в обход государственного регулятора. Вашу компанию разделяют на несколько десятков компаний среднего масштаба, а вам удается избежать ответственность с помощью взяток и сохранить часть имущества за собой.'
        },
        {
            question: 7,
            id: 2,
            text: 'Технологии есть, финансы есть, пора набирать свою корпоративную армию!',
            redFlag: true,
            ending: 'Вы нанимаете себе на службу бывших солдат с боевым опытом. Постепенно, у вас складывается мощный профессиональный костяк  и вы, мало-помалу тренируете свою армию в локальных конфликтах. Все складывается хорошо, у вашей организации появились планы по масштабированию армии. Все перекрывает странная случайность, когда во время перелета самые лучшие бойцы вашей частной армии гибнут из-за непредвиденного крушения самолета.'
        },
        {
            question: 7,
            id: 3,
            text: 'Мировой порядок это хорошо, а еще лучше мировой хаос! С помощью финансового и технологического влияния своей компании, вы решаете обрушить рынки по торговле ценными бумагами и отключить все финансовые приложения. Вот это будет хаос!',
            redFlag: true,
            ending: 'Хаос действительно произошел, только в вашей стремительно обанкротившейся компании. Вы остались практически ни с чем. Только и остается, вспоминать о днях былого величия…'
        },
        {
            question: 7,
            id: 4,
            text: 'Пока есть возможность, необходимо создавать будущее, в котором всем будет хорошо! Нужно вложиться в создание образовательных платформ, зелёную энергетику, заводы по переработке мусора.',
            redFlag: false,
            ending: 'Вы вкладываетесь в создание новых материалов взамен пластика, сажаете леса по всему миру от Южной Америки до Африки, осваиваете новые материалы для экологичного производства, работаете над утилизацией химикатов. Поначалу ваш вклад незаметен, но со временем вы становитесь личностью мирового масштаба и получаете признание за вклад в будущее человечества.'
        },
        {
            question: 7,
            id: 5,
            text: 'А может прикупить ядерное оружие? Будем суверенной IT компанией!',
            redFlag: true,
            ending: 'Вы находите на черном рынке плутоний и начинаете создание своей атомной бомбы, прикрываясь передовым изучением частиц как в адронном коллайдере. Все протекало хорошо, однако по какой-то причине, рванули ядерные реакторы в вашем подземном комплексе. После происшествия внимание общественности было приковано к вам и вашим экспериментам. Вас очень быстро разоблачили и отобрали все бизнес-активы, лишив вас влияния и могущества.'
        }
    ]);
    const [redFlagSum, setRedFlag] = useState(1);
    const [mainVisibility, setMainVisibility] = useState(true);
    const [initFrameVisibility, setInitFrameVisibility] = useState(true);
    const [situationVisibility, setSituationVisibility] = useState(false);
    const [outcomeVisibility, setOutcomeVisibility] = useState(false);
    const [landingVisibility, setLandingVisibility] = useState(true);
    const [cardsVisibility, setCardsVisibility] = useState(false);
    const [situationIsVisible, setSituationVisible] = useState(true);
    const [timerActive, setTimerActive] = useState(false);
    const [timerVisibility, setTimerVisibility] = useState(false);
    const [UIVisibility, setUIVisibility] = useState(false);
    const [endings, setEndings] = useState([''])

    function handleStopgameClick() {
        document.querySelector('body').classList.add('blackBack');
        document.querySelector('.main').classList.add('displayNone');
        if (situationVisibility) {
            setTimerActive(false);
            setTimerVisibility(false);
            if (cardsVisibility) {
                document.querySelector('.answers__button').classList.add('button-toLeft')
            }
        }
        document.querySelector('body').classList.add('of-visible');
        document.querySelector('.stopgame-button').classList.add('displayNone');
        setLandingVisibility(true);
    }

    useEffect(() => {
        if (window.innerWidth < 800) {
            setMainVisibility(false);
            setLandingVisibility(true);
            document.querySelector('body').classList.add('blackBack');
        }
    }, [])

    return (
        <BrowserRouter>
          <Routes>
                <Route path='/' element={
                    <>
                    {mainVisibility &&
                <main className='main displayNone'>
                    <div className='stopgame-button' onClick={handleStopgameClick}>
                        <span className='stopgame-span'>свернуть игру</span>
                    </div>
                    {initFrameVisibility && <InitialFrame
                        setInitFrameVisibility={setInitFrameVisibility}
                        setSituationVisibility={setSituationVisibility}
                        text={'Кто-то ввел код IDDQD. Теперь вы неуязвимы и не можете умереть. Начните проживать жизнь бессмертного героя…'}
                        button={'Начать'}
                    />}
                    {situationVisibility && <Situation
                        situationList={situationList}
                        answerList={answerList}
                        redFlagSum={redFlagSum}
                        setRedFlag={setRedFlag}
                        outcomeVisibility={outcomeVisibility}
                        setSituationVisibility={setSituationVisibility}
                        setOutcomeVisibility={setOutcomeVisibility}
                        endings={endings}
                        setEndings={setEndings}
                        timerActive={timerActive}
                        setTimerActive={setTimerActive}
                        timerVisibility={timerVisibility}
                        setTimerVisibility={setTimerVisibility}
                        cardsVisibility={cardsVisibility}
                        setCardsVisibility={setCardsVisibility}
                        situationIsVisible={situationIsVisible}
                        setSituationVisible={setSituationVisible}
                    />}
                    {outcomeVisibility && <Outcome
                        redFlagSum={redFlagSum}
                        UIVisibility={UIVisibility}
                        setUIVisibility={setUIVisibility}
                        endings={endings}/>}
                </main>}
            {landingVisibility &&
                <Landing
                    setLandingVisibility={setLandingVisibility}
                    setMainVisibility={setMainVisibility}
                    situationVisibility={situationVisibility}
                    cardsVisibility={cardsVisibility}
                    setTimerActive={setTimerActive}
                    setTimerVisibility={setTimerVisibility}
                />}
                    </>
                }></Route>
                <Route path='policy' element={<Policy />}></Route>
          </Routes>
        </BrowserRouter>
    );
}

export default App;
