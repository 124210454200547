import React from 'react';

function Card(props) {

    function handleClick() {
        if (props.redFlag === true) {
            props.setRedFlag(props.redFlagSum + 1)
        }

        if (props.ending) {
            props.endings[props.count] = props.ending;
        }

        props.resetTimer();
        props.setCount(props.count + 1);
        document.querySelector('.answers__button').classList.remove('button-toLeft')
        props.setSituationVisibility(!props.situationVisibility);
        props.setTimerVisibility(!props.timerVisibility);
    }

    return (
        <article className='card' onClick={handleClick}>
            {props.text}
        </article>
    );
}

export default Card;