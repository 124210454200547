import React, {useEffect, useState} from 'react';
import CardsContainer from "./CardsContainer";
import CountDown from "./CountDown";
import {CSSTransition} from "react-transition-group";

function Situation(props) {
    const [count, setCount] = useState(0);
    const answerArr = props.answerList.filter(item => item.question === count + 1);
    //--timer--//
    const [ seconds, setSeconds ] = React.useState(30);
    const [noAnswerCount, setNoAnswer] = React.useState(-1);

    function resetTimer() {
        props.setTimerActive(false);
        setCount(count + 1);
        setSeconds(30);
        props.setCardsVisibility(false);
    }

    function handleClick() {
        props.setSituationVisible(false);
        props.setTimerVisibility(true);
        props.setTimerActive(true);
        props.setCardsVisibility(true);
        if (!document.querySelector('.answers__button').classList.contains('button-toLeft')) {
            setNoAnswer(noAnswerCount + 1);
        }
        document.querySelector('.answers__button').classList.add('button-toLeft')
    }

    useEffect(() => {
        if (count > 6) {
            props.setSituationVisibility(false);
            props.setOutcomeVisibility(true);
            document.querySelector('.main').classList.remove('outcome__img7-emotion','outcome__img6-emotion','outcome__img5-emotion','outcome__img4-emotion','outcome__img3-emotion','outcome__img2-emotion','outcome__img1-emotion',);
        }

        if (count > -1) {
            document.querySelector('.situation').classList.add(`outcome__img${count+1}-emotion`, 'outcome__img');
        }
    })

    return (
        <>
            <div className='situation'>
                {props.timerVisibility && <div className='timer'>
                    <CountDown
                        count={count}
                        setCount={setCount}
                        setCardsVisibility={props.setCardsVisibility}
                        seconds={seconds}
                        setSeconds={setSeconds}
                        timerActive={props.timerActive}
                        setTimerActive={props.setTimerActive}
                        resetTimer={resetTimer}
                        endings={props.endings}
                        setEndings={props.setEndings}
                        answerList={props.answerList}
                        setRedFlag={props.setRedFlag}
                        redFlagSum={props.redFlagSum}
                        setSituationVisibility={props.setSituationVisible}
                        situationVisibility={props.situationIsVisible}
                        timerVisibility={props.timerVisibility}
                        setTimerVisibility={props.setTimerVisibility}
                        noAnswerCount={noAnswerCount}
                        />
                </div>}
            </div>
                <div className='answers-section'>
                    <CSSTransition in={props.situationIsVisible} timeout={300} classNames='cardsContainer' unmountOnExit>
                        <p className='situation__text'>{props.situationList[count]}</p>
                    </CSSTransition>
                    <button className={'button situation__button answers__button'} onClick={handleClick}>
                    </button>
                    <CSSTransition in={props.cardsVisibility} timeout={300} classNames='cardsContainer' unmountOnExit>
                        <CardsContainer
                            answers={answerArr}
                            count={count}
                            setCount={setCount}
                            redFlagSum={props.redFlagSum}
                            setRedFlag={props.setRedFlag}
                            outcomeVisibility={props.outcomeVisibility}
                            setOutcomeVisibility={props.setOutcomeVisibility}
                            endings={props.endings}
                            setEndings={props.setEndings}
                            resetTimer={resetTimer}
                            setSituationVisibility={props.setSituationVisible}
                            situationVisibility={props.situationIsVisible}
                            timerVisibility={props.timerVisibility}
                            setTimerVisibility={props.setTimerVisibility}/>
                    </CSSTransition>
                </div>
        </>
    );
}

export default Situation;
