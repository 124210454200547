import React from 'react';

import Card from "./Card";
function CardsContainer(props) {
    return (
            <div className='cardsContainer'>
                {props.answers.map(item => <Card
                    text={item.text}
                    question={item.question}
                    id={item.id}
                    key={item.id}
                    ending={item.ending}
                    redFlag={item.redFlag}
                    count={props.count}
                    setCount={(props.setCount)}
                    redFlagSum={props.redFlagSum}
                    setRedFlag={props.setRedFlag}
                    endings={props.endings}
                    setEndings={props.setEndings}
                    resetTimer={props.resetTimer}
                    setSituationVisibility={props.setSituationVisibility}
                    situationVisibility={props.situationVisibility}
                    timerVisibility={props.timerVisibility}
                    setTimerVisibility={props.setTimerVisibility}
                />)}
            </div>
    );
}

export default CardsContainer;