import React from 'react';
function InitialFrame(props) {
    function handleClick() {
        props.setInitFrameVisibility(false);
        props.setSituationVisibility(true);
    }

    return (
        <div className='initialFrame'>
            <p id='text'>{props.text}</p>
            <button className='button next-btn' id='button'
                    onClick={handleClick}></button>
        </div>
    );
}


export default InitialFrame;